import {Card, Elevation, HTMLTable, Intent, Tag} from '@blueprintjs/core'

import './WebsiteCard.scss'

const WebsiteCard = ({profile}: any) => {
    return (
        <Card className="website-card" elevation={Elevation.TWO}>
            <div className="website-header">
                <div className="website-link">
                    <a href={"/redirect/" +  profile.subdomain} target="_blank" rel="noreferrer">{profile.subdomain}</a>
                </div>
                <div className="status-code">
                    <Tag key={profile.statusCode} intent={Intent.SUCCESS}>{profile.statusCode}</Tag>
                </div>
            </div>
            <div className="address-header bp4-text-muted">
                {profile.addresses.join(', ')}
            </div>
            <div className="profile-content row">
                <div className="snapshot-wrapper col-md-6">
                    <img className="snapshot" alt="website snapshot" src={"/snapshot/" + profile.subdomain}/>
                </div>
                <div className="title-techologies-block col-md-6">
                    <div className="website-title">{profile.title}</div>
                    <div className="certificate-block col-md-6">
                        <HTMLTable striped compact>
                            <tbody>
                            <tr>
                                <td className="certificate-key">Subject:</td>
                                <td>{profile.certificate.subject}</td>
                            </tr>
                            <tr>
                                <td className="certificate-key">Alt&nbsp;names:</td>
                                <td>{profile.certificate.altNames.join(', ')}</td>
                            </tr>
                            <tr>
                                <td className="certificate-key">Not&nbsp;before:</td>
                                <td>{profile.certificate.notBefore}</td>
                            </tr>
                            <tr>
                                <td className="certificate-key">Not&nbsp;after:</td>
                                <td>{profile.certificate.notAfter}</td>
                            </tr>
                            </tbody>
                        </HTMLTable>
                    </div>
                </div>
            </div>
            <div className="technologies row">
                <div className="col-md-12">
                    {profile.technologies.map((technology: any) => {
                        return (
                            <Tag key={technology} intent={Intent.PRIMARY}>{technology}</Tag>
                        )
                    })}
                </div>
            </div>
        </Card>
    )
}

export default WebsiteCard