import {Button, ButtonGroup, Card} from "@blueprintjs/core";
import HistogramBlock from "./HistogramBlock";
import {useState} from "react";

const HistogramsSidebar = ({histograms}: any) => {
    const [histogramsCollapsed, setHistogramsCollapsed] = useState(false)
    const [collapseHint, setCollapseHint] = useState(0)

    const collapseHistograms = (collapse: boolean) => () => {
        setHistogramsCollapsed(collapse)
        setCollapseHint(collapseHint + 1)
    }

    return (
        <div className={"histograms-block"}>
            <Card className="histograms-toolbar">
                <div className="histograms-toolbar-title">Histograms</div>
                <ButtonGroup>
                    <Button icon="double-chevron-up" onClick={collapseHistograms(true)} small
                            outlined={false}/>
                    <Button icon="double-chevron-down" onClick={collapseHistograms(false)} small
                            outlined={false}/>
                </ButtonGroup>
            </Card>
            <div className="histograms">
                {histograms.map((histogram: any, index: number) => {
                    return <HistogramBlock key={index} histogram={histogram}
                                           collapsed={histogramsCollapsed}
                                           collapseHint={collapseHint}/>
                })}
            </div>
        </div>
    )
}

export default HistogramsSidebar