import React from 'react'
import ReactDOM from 'react-dom/client'
import 'normalize.css/normalize.css'
import '@blueprintjs/core/lib/css/blueprint.css'
import 'bootstrap/dist/css/bootstrap-grid.css'
import './assets/css/fonts.css'
import './index.scss'
import App from './App'
import reportWebVitals from './reportWebVitals'
import {createBrowserRouter, RouterProvider} from 'react-router-dom'
import DomainView from './views/DomainView'
import MonitorView from "./views/MonitorView";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
    },
    {
        path: "/domain/:domain",
        element: <DomainView />,
    },
    {
        path: "/monitor",
        element: <MonitorView />
    }
]);

root.render(
  // <React.StrictMode>
      <RouterProvider router={router} />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
