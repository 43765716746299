import {useEffect, useState} from 'react'
import moment from 'moment'
import {Alignment, HTMLTable, Navbar, NavbarGroup, NavbarHeading, Tab, TabId, Tabs} from '@blueprintjs/core'

import './MonitorView.scss'

const formatTime = (timestamp: any) => {
    return moment(timestamp).format("D MMMM YYYY HH:mm:ss")
}

const taskDuration = (end: any, start: any) => {
    const stopMoment = moment(end)
    const startMoment = moment(start)
    return stopMoment.diff(startMoment, 'seconds')
}

const TasksPanel = ({tasks}: any) => {
    return (
        <div className={"tasks-tab"}>
            <HTMLTable striped>
                <thead>
                <tr>
                    <th>Domain</th>
                    <th>Started</th>
                    <th>Stopped</th>
                    <th>Duration</th>
                    <th>Subdomains</th>
                    <th>Websites</th>
                    <th>No websites</th>
                    <th>No ip</th>
                    <th>Views</th>
                    <th>Exceptions</th>
                </tr>
                </thead>
                <tbody>
                {tasks.map((task: any, index: number) =>
                    <tr key={index}>
                        <td><a href={"/domain/" + task.domain}>{task.domain}</a></td>
                        <td>{formatTime(task.startedAt)}</td>
                        <td>{formatTime(task.stoppedAt)}</td>
                        <td>{taskDuration(task.stoppedAt, task.startedAt)}s</td>
                        <td>{task.subdomainsCount}</td>
                        <td>{task.websitesCount}</td>
                        <td>{task.nowebsitesCount}</td>
                        <td>{task.noipCount}</td>
                        <td>{task.viewsCount}</td>
                        <td>{task.exceptionsCount}</td>
                    </tr>
                )}
                </tbody>
            </HTMLTable>
        </div>
    )
}

const QueriesPanel = ({queries}: any) => {
    return (
        <div className={"queries-tab"}>
            <HTMLTable striped>
                <thead>
                <tr>
                    <th>Query</th>
                    <th>IP</th>
                    <th>Time</th>
                    <th>User-Agent</th>
                </tr>
                </thead>
                <tbody>
                {queries.map((query: any, index: number) =>
                    <tr key={index}>
                        <td>{query.query}</td>
                        <td>{query.ip}</td>
                        <td>{formatTime(query.createdAt)}</td>
                        <td>{query.userAgent}</td>
                    </tr>
                )}
                </tbody>
            </HTMLTable>
        </div>
    )
}

const RedirectsPanel = ({redirects}: any) => {
    return (
        <div className={"redirects-tab"}>
            <HTMLTable striped>
                <thead>
                <tr>
                    <th>Website</th>
                    <th>IP</th>
                    <th>Time</th>
                    <th>User-Agent</th>
                </tr>
                </thead>
                <tbody>
                {redirects.map((redirect: any, index: number) =>
                    <tr key={index}>
                        <td><a href={"/redirect/" +  redirect.website} target="_blank" rel="noreferrer">{redirect.website}</a></td>
                        <td>{redirect.ip}</td>
                        <td>{formatTime(redirect.createdAt)}</td>
                        <td>{redirect.userAgent}</td>
                    </tr>
                )}
                </tbody>
            </HTMLTable>
        </div>
    )
}

const MonitorView = () => {

    const [tasks, setTasks] = useState([])
    const [queries, setQueries] = useState([])
    const [redirects, setRedirects] = useState([])
    const [tabId, setTabId] = useState<TabId>("tasks")

    const handleTabChange = (tabId: TabId) => {
        setTabId(tabId)
    }

    useEffect(() => {
        fetch('/api/v1/monitor/tasks').then(response => response.json()).then(data => {
            setTasks(data)
        })
        fetch('/api/v1/monitor/queries').then(response => response.json()).then(data => {
            setQueries(data)
        })
        fetch('/api/v1/monitor/redirects').then(response => response.json()).then(data => {
            setRedirects(data)
        })
    }, [])

    return (
        <div className={"monitor-view"}>
            <Navbar>
                <NavbarGroup align={Alignment.LEFT}>
                    <NavbarHeading><a href={"/"}>DOMMAP</a></NavbarHeading>
                </NavbarGroup>
                <NavbarGroup align={Alignment.RIGHT}>
                    <NavbarHeading><a href={"https://sibir.tech"}>SIBIR TECHNOLOGIES</a></NavbarHeading>
                </NavbarGroup>
            </Navbar>
            <div className={"monitor-content container-fluid"}>
                <Tabs id="monitor-tabs" onChange={handleTabChange} selectedTabId={tabId}>
                    <Tab id="tasks" title="Tasks" panel={<TasksPanel tasks={tasks}/>}/>
                    <Tab id="queries" title="Queries" panel={<QueriesPanel queries={queries}/>}/>
                    <Tab id="redirects" title="Redirects" panel={<RedirectsPanel redirects={redirects}/>}/>
                </Tabs>
            </div>
        </div>
    )
}

export default MonitorView

