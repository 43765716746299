import {Card, Icon} from '@blueprintjs/core'
import {useEffect, useState} from 'react'

import './HistogramBlock.scss'

const HistogramBlock = ({histogram, collapsed, collapseHint}: any) => {
    const [_collapsed, setCollapsed] = useState(false)

    const toggleCollapsed = () => {
        setCollapsed(!_collapsed)
    }

    useEffect(() => {
        setCollapsed(collapsed)
    }, [collapsed, collapseHint])

    const chevronIcon = () => {
        return _collapsed ? "chevron-left" : "chevron-down"
    }

    return (
        <Card className="histogram-block">
            <div className={"histogram-block-header"}>
                <span className="histogram-block-title">{histogram.title}&nbsp;({histogram.items.length})</span>
                <Icon icon={chevronIcon()} onClick={toggleCollapsed}/>
            </div>
            <div className="histogram-content">
                {!_collapsed && histogram.items.map((item: any, index: number) => {
                  return (
                      <div className="item-row" key={index}>
                          <div className="item-name">{item.name}</div>
                          <div className="item-count">{item.count}</div>
                      </div>
                  )
                })}
            </div>
        </Card>
    )
}

export default HistogramBlock