import React from 'react'
import './App.css'
import IndexView from './views/IndexView'

function App() {
  return (
    <div className="App">
      <IndexView/>
    </div>
  );
}

export default App;
