import {
    Alignment, AnchorButton,
    Button,
    H1, Icon,
    InputGroup, Intent,
    Navbar,
    NavbarGroup,
    NavbarHeading
} from '@blueprintjs/core'
import {useState} from 'react'
import {useNavigate} from 'react-router-dom'

import './IndexView.scss'

const IndexView = () => {
    const navigate = useNavigate()
    const [searchQuery, setSearchQuery] = useState("")

    const onSearchButtonClick = () => {
        fetch('/api/v1/domain/' + searchQuery, {
            method: 'POST',
        }).then(response => {
            navigate('/domain/' + searchQuery)
        })
    }

    const onHackerOneClick = () => {
        fetch('/api/v1/domain/hackerone.com', {
            method: 'POST',
        }).then(response => {
            navigate('/domain/hackerone.com')
        })
    }

    const searchDisabled = (query: string) => {
        return query === ""
    }

    const searchButton = <Button intent={Intent.PRIMARY} onClick={onSearchButtonClick} disabled={searchDisabled(searchQuery)}>Discover</Button>

    return (
        <>
            <Navbar>
                <NavbarGroup align={Alignment.LEFT}>
                    <NavbarHeading><a href={"/"}>DOMMAP</a></NavbarHeading>
                </NavbarGroup>
                <NavbarGroup align={Alignment.RIGHT}>
                    <NavbarHeading><a href={"https://sibir.tech"}>SIBIR TECHNOLOGIES</a></NavbarHeading>
                </NavbarGroup>
            </Navbar>
            <div className="page-content index-page-content">
                <div className="search-form">
                    <H1>Enter a domain name</H1>
                    <InputGroup fill={true} large={true}
                                leftElement={<Icon icon="search"/>}
                                placeholder="Domain... (i.e. hackerone.com)"
                                value={searchQuery} onChange={event => setSearchQuery(event.target.value)}
                                rightElement={searchButton}
                    />
                    <AnchorButton className="hackerone-button" onClick={onHackerOneClick}>
                        <div>
                            <div>or just click to see</div>
                            <div className="sample-name">hackerone.com</div>
                            <div>domain results</div>
                        </div>
                    </AnchorButton>
                </div>
            </div>
        </>
    )
}

export default IndexView