import {useEffect, useState} from 'react'
import {useParams, useNavigate} from 'react-router-dom'
import {
    Alignment,
    Button, Callout, Card, Divider, H2, H5,
    Icon,
    InputGroup,
    Intent,
    Navbar,
    NavbarGroup,
    NavbarHeading, NonIdealState,
    ProgressBar, Tab, TabId, Tabs, Tag
} from '@blueprintjs/core'
import WebsiteCard from '../components/WebsiteCard'
import SkelWebsiteCard from '../components/SkelWebsiteCard'
import HistogramsSidebar from '../components/HistogramsSidebar'

import './DomainView.scss'

const UPDATE_TIMEOUT = 2000

const DNSTab = ({records}: any) => {
    return (
        <Card className="domain-info-tab dns-tab">
            <H5>DNS</H5>
            {records.map((record: any, index: number) => {
                return <div key={index}>{record.name}&nbsp;{record.type}&nbsp;{record.value}</div>
            })}
        </Card>
    )
}

const NoWebTab = ({profiles}: any) => {
    return (
        <Card className="domain-info-tab no-websites-tab">
            <H5>No websites&nbsp;({profiles.length})</H5>
            {profiles.map((profile: any, index: number) => {
                return <div key={index}>{profile.subdomain}</div>
            })}
        </Card>
    )
}

const NoAddrTab = ({profiles}: any) => {
    return (
        <Card className="domain-info-tab no-address-tab">
            <H5>No addresses&nbsp;({profiles.length})</H5>
            {profiles.map((profile: any, index: number) => {
                return <div key={index}>{profile.subdomain}</div>
            })}
        </Card>
    )
}

const DomainView = () => {
    const {domain} = useParams()
    const navigate = useNavigate()
    const [taskState, setTaskState] = useState<any>({
        state: 'PROCESSING',
        progress: 0,
        dnsRecords: [],
        websiteProfiles: [],
        noWebsiteProfiles: [],
        noAddressProfiles: [],
        histograms: []
    })
    const [progressVisible, setProgressVisible] = useState<boolean>(true)
    const [searchQuery, setSearchQuery] = useState(domain)
    const [tabId, setTabId] = useState<TabId>("dns-tab")
    const [domainWithHash, setDomainWithHash] = useState(domain)

    useEffect(() => {
        let intervalID: NodeJS.Timer
        const updateState = () => {
            fetch('/api/v1/domain/' + domain).then(response => response.json()).then(task => {
                setTaskState(task)
                setDomainWithHash(domain + "?" + Date.now())
                if (task.state === 'DONE') {
                    clearInterval(intervalID)
                    setProgressVisible(false)
                    return
                }
            })
        }

        intervalID = setInterval(updateState, UPDATE_TIMEOUT)
        return () => clearInterval(intervalID)
    }, [domain])

    const onSearchButtonClick = () => {
        fetch('/api/v1/domain/' + searchQuery, {
            method: 'POST',
        }).then(_ => {
            navigate('/domain/' + searchQuery)
            setTaskState({
                state: 'PROCESSING',
                progress: 0,
                dnsRecords: [],
                websiteProfiles: [],
                noWebsiteProfiles: [],
                noAddressProfiles: [],
                histograms: []
            })
            setProgressVisible(true)
        })
    }

    const handleTabChange = (tabId: TabId) => {
        setTabId(tabId)
    }

    const searchDisabled = (query: string | undefined) => {
        return !query && query === ""
    }

    const searchButton = <Button
        intent={Intent.PRIMARY}
        onClick={onSearchButtonClick}
        disabled={searchDisabled(searchQuery)}>Discover</Button>

    return (
        <>
            <Navbar>
                <NavbarGroup align={Alignment.LEFT}>
                    <NavbarHeading><a href={"/"}>DOMMAP</a></NavbarHeading>
                </NavbarGroup>
                <NavbarGroup align={Alignment.RIGHT}>
                    <NavbarHeading><a href={"https://sibir.tech"}>SIBIR TECHNOLOGIES</a></NavbarHeading>
                </NavbarGroup>
            </Navbar>
            <div className="page-content result-page-content">
                <div className="search-form">
                    <InputGroup fill={true} large={true}
                                leftElement={<Icon icon="search"/>}
                                placeholder="Domain... (i.e. hackerone.com)"
                                value={searchQuery} onChange={event => setSearchQuery(event.target.value)}
                                rightElement={searchButton}
                    />
                </div>
                <div className="domain-info container-fluid">
                    <div className="row">
                        <div className="col-md-4">
                            <img alt="domain mugshot" className="domain-mugshot" src={"/mugshot/" + domainWithHash}/>
                        </div>
                        <div className="col-md-8">
                            <div className="domain-info-title"><H2>{domain}</H2></div>
                            <Divider/>
                            <div className="tabs-container">
                                <Tabs selectedTabId={tabId} onChange={handleTabChange} vertical fill>
                                    <Tab id="dns-tab" title="DNS" tagContent={taskState.dnsRecords.length}
                                         panel={<DNSTab records={taskState.dnsRecords}/>}/>
                                    <Tab id="no-web-tab" title="No websites"
                                         tagContent={taskState.noWebsiteProfiles.length}
                                         panel={<NoWebTab profiles={taskState.noWebsiteProfiles}/>}/>
                                    <Tab id="no-addr-tab" title="No addresses"
                                         tagContent={taskState.noAddressProfiles.length}
                                         panel={<NoAddrTab profiles={taskState.noAddressProfiles}/>}/>
                                </Tabs>
                            </div>
                            <Divider/>
                            <Callout intent={Intent.PRIMARY} title="We need your feedback">
                                If you have some ideas about service or feature request write to e-mail&nbsp;
                                <Tag intent={Intent.PRIMARY} minimal><a href="mailto:sibir@sibir.tech">sibir@sibir.tech</a></Tag>.
                            </Callout>
                        </div>
                    </div>
                </div>
                <Divider/>
                {progressVisible &&
                    <div>
                        <ProgressBar value={taskState.progress / 100} intent={Intent.PRIMARY}/>
                        <div>{taskState.message}</div>
                    </div>
                }
                <div className="result-container container-fluid">
                    {taskState.websiteProfiles.length === 0 && taskState.state !== 'DONE' &&
                        <div className="row">
                            <div className="websites-block col-md-10">
                                <H2 className="websites-header">Websites ({taskState.websiteProfiles.length})</H2>
                                <div className="results row">
                                    <div className="col-12 col-md-6 profile-wrapper">
                                        <SkelWebsiteCard/>
                                    </div>
                                    <div className="col-12 col-md-6 profile-wrapper">
                                        <SkelWebsiteCard/>
                                    </div>
                                </div>
                            </div>
                            <div className="histograms-block col-md-2">

                            </div>
                        </div>
                    }
                    {taskState.websiteProfiles.length === 0 && taskState.state === 'DONE' &&
                        <NonIdealState icon="search" title="No web sites was found"
                                       description="Your discover didn't match any websites. Try searching for something else."/>
                    }
                    {taskState.websiteProfiles.length !== 0 &&
                        <div className="row">
                            <div className="websites-block col-md-10">
                                <H2 className="websites-header">Websites ({taskState.websiteProfiles.length})</H2>
                                <div className="website-cards">
                                    <div className="results row">
                                        {taskState.websiteProfiles.map((profile: any, index: number) => {
                                            return (
                                                <div key={index} className="col-12 col-md-6 profile-wrapper">
                                                    <WebsiteCard profile={profile}/>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-2">
                                <HistogramsSidebar histograms={taskState.histograms}/>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </>
    )
}

export default DomainView