import {Card, Classes, Elevation, HTMLTable, Intent, Tag} from '@blueprintjs/core'

import './WebsiteCard.scss'

const SkelWebsiteCard = () => {
    return (
        <Card className="website-card" elevation={Elevation.TWO}>
            <div className="website-header">
                <div className={"website-link " + Classes.SKELETON}>
                    <a href="/" target="_blank" rel="noreferrer">example.com</a>
                </div>
                <div className="status-code">
                    <Tag className={Classes.SKELETON} key={"200"} intent={Intent.SUCCESS}>200</Tag>
                </div>
            </div>
            <div className={"address-header bp4-text-muted " + Classes.SKELETON}>1.1.1.1, 2.2.2.2, 3.3.3.3</div>
            <div className="profile-content row">
                <div className={"snapshot-wrapper col-md-6"}>
                    <img className={"snapshot " + Classes.SKELETON} alt="website snapshot" src={"/snapshot/example.com"}/>
                </div>
                <div className="title-techologies-block col-md-6">
                    <div className={"website-title " + Classes.SKELETON}>Example website title</div>
                    <div className="certificate-block col-md-6">
                        <HTMLTable striped compact className={Classes.SKELETON}>
                            <tbody>
                            <tr>
                                <td className="certificate-key">Subject:</td>
                                <td>OU=example company</td>
                            </tr>
                            <tr>
                                <td className="certificate-key">Alt&nbsp;names:</td>
                                <td>smtp.example.com, test.example.com</td>
                            </tr>
                            <tr>
                                <td className="certificate-key">Not&nbsp;before:</td>
                                <td>2022-03-21</td>
                            </tr>
                            <tr>
                                <td className="certificate-key">Not&nbsp;after:</td>
                                <td>2023-03-21</td>
                            </tr>
                            </tbody>
                        </HTMLTable>
                    </div>
                </div>
            </div>
            <div className="technologies row">
                <div className="col-md-12">
                    <Tag className={Classes.SKELETON} key="Nginx" intent={Intent.PRIMARY}>Nginx</Tag>
                    <Tag className={Classes.SKELETON} key="Wordpress" intent={Intent.PRIMARY}>Wordpress</Tag>
                    <Tag className={Classes.SKELETON} key="PHP" intent={Intent.PRIMARY}>PHP</Tag>
                </div>
            </div>
        </Card>
    )
}

export default SkelWebsiteCard